import React from "react";
import { useNavigate } from "react-router-dom";

const CardModel = ({ model }) => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/part/${id}`);
  };
  return (
    <div className="w-[20rem] h-[28rem] flex flex-col bg-white drop-shadow rounded-xl justify-between font-Roboto items-center overflow-hidden">
      <div className="w-full h-4/5 flex">
        <img
          src={model.image}
          alt="model img"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex gap-2 h-1/5 w-full items-center justify-between p-3 bg-light-gray">
        <div className="details  capitalize font-md">{model.name}</div>
        <button
          className="btn black text-white"
          onClick={() => handleClick(model._id)}
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default CardModel;
