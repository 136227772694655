import "animate.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ResponsiveAppBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Brand from "./pages/Brand";
import Model from "./pages/Model";
import Parts from "./pages/Parts";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import TermsAndConditions from "./pages/TermsAndConditions";

function App() {
  return (
    <BrowserRouter>
      <ResponsiveAppBar />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:brandType" element={<Brand />} />
        <Route path="/model/:id/:brandType" element={<Model />} />
        <Route path="/part/:id" element={<Parts />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
