// import React from "react";

// const ContactPage = () => {
//   return (

// export default ContactPage;
import axios from "axios";
import React, { useState } from "react";
import DescriptionAlerts from "../common/Alert";

function ContactUs() {

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    subject: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API_URL}/contactus/create`,{
      name:formData.name,
      email:formData.email,
      phoneNo:formData.phone,
      theme:formData.subject,
      message:formData.message
    })
    .then((res)=>{
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
        subject: "",
      });
        setSeverity("Success");
        setAlertMessage(
          "Thank You! your message has been sent sucessfully"
        );
        setOpenSnackbar(true);
    })
    .catch((err) => {
      setSeverity("Warning");
      setAlertMessage(err.response.data.error);
      setOpenSnackbar(true);
      console.log(err);
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="section2 flex flex-col font-Roboto mb-5">
      <div className="context flex-col">
        <div className="flex flex-col">
          <h1 className="h1">Kontaktiere uns</h1>
          <p className="text-gray-600 mb-8">
          Sie haben eine Frage, benötigen Unterstützung oder möchten einen Reparaturservice buchen? Kontaktieren Sie uns gerne über die untenstehenden Kontaktdaten oder indem Sie das Kontaktformular ausfüllen
          </p>
        </div>
      </div>
      <div className="flex context h-full items-start gap-5 sm:flex-col">
        <div className="flex flex-col gap-5">
          <div>
            <h2 className="text-xl font-semibold mb-4">Adresse</h2>
            <p className="text-base text-gray-600 mb-4">Gilbert Vincent Shop</p>
            <p className="text-base text-gray-600 mb-4">Dorf 25</p>
            <p className="text-base text-gray-600 mb-4">6162 Entlebuch</p>
            <p className="text-base text-gray-600 mb-4">Schweiz</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-4">Kontaktinformationen</h2>
            <p className="text-base text-gray-600 mb-4">
              Email: info@gilbertvincent.ch
            </p>
            <p className="text-base text-gray-600 mb-4">
              Call: +41783081777
            </p>
            <p className="text-base text-gray-600 mb-4">
              Öffnungszeiten: Mo-Fr, 9:00 - 18:00 Uhr
            </p>
          </div>
        </div>

        <div className="w-2/3 sm:w-full">
          <h2 className=" text-2xl font-semibold mb-4">In Kontakt kommen</h2>
          {openSnackbar && (
        <DescriptionAlerts
          handleCloseSnackbar={handleCloseSnackbar}
          severity={severity}
          alertMessage={alertMessage}
        />
      )}
          <form
            onSubmit={handleSubmit}
            className="flex flex-wrap gap-5 justify-between"
          >
            <div className="w-[45%]">
              <div className="mb-4">
                <label htmlFor="name" className=" block mb-2">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 bg-contact-gray text-black rounded-lg"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className=" block mb-2">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 bg-contact-gray text-black rounded-lg"
                />
              </div>
            </div>
            <div className="w-[45%]">
              <div className="mb-4">
                <label htmlFor="subject" className=" block mb-2">
                  Thema:
                </label>
                <input
                  type="subject"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 bg-contact-gray text-black rounded-lg"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className=" block mb-2">
                Telefon:
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 bg-contact-gray text-black rounded-lg"
                />
              </div>
            </div>

            <div className="w-full">
              <label htmlFor="message" className=" block mb-2">
              NachrichtL:
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 bg-contact-gray text-black rounded-lg"
              />
            </div>
            <button
              type="submit"
              className="p-2 rounded bg-bg-dark text-white w-full"
            >
              einreichen
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
