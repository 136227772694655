import React from "react";
import { PiPhoneCallBold } from "react-icons/pi";
import img_1 from "../Img/mission.webp";
import { Link } from "react-router-dom";


const AboutUs = () => {
  return (
    <div className="section2">
      <div className="context flex sm:flex-col xl:flex-row items-start justify-center gap-5">
        {/* Content Section */}
        <div className="lg:w-1/2 gap-5">
          <h1 className="h1">Über uns</h1>
          <p className="text-lg text-gray-600 mb-8">
            Bei Expert Repair ist es unsere Leidenschaft, erstklassige Reparaturdienste für Mobiltelefone, Laptops und Computer anzubieten. Mit jahrelanger Erfahrung in der Branche haben wir uns einen guten Ruf für unser Fachwissen, unsere hochwertigen Reparaturen und unseren außergewöhnlichen Kundenservice aufgebaut<br /><br />

            Unser Ziel ist es, Professionalität, guten Service und Vertrauen in das Reparatur- und Wartungsgeschäft zu bringen. Wir sind sehr stolz darauf, einige der professionellsten Techniker zu Ihren Geräten zu schicken, um Dinge zu reparieren, die nicht funktionieren
          </p>

          <div className="flex items-center gap-5">
            {<Link to="/contactus" className="btn bg-bg-dark text-white">kontaktiere uns</Link>}
            <button>
              <PiPhoneCallBold className="text-[3rem] text-white bg-bg-dark p-[10px] rounded-full hover:bg-white hover:text-bg-dark border-2 border-bg-dark transition-all" />
            </button>
            <p className="text-xl">+41783081777</p>
          </div>
        </div>

        {/* Image Section */}
        <div className="lg:w-1/2">
          <img
            src={img_1}
            alt="mission img"
            className="w-full h-full object-cover rounded-xl"
          />
        </div>
      </div>
    </div>
  );
};







export default AboutUs;
