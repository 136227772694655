/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import logo from "../Img/logo.png";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
} from "react-icons/fa";

import { Link } from "react-router-dom";



function Footer() {
  return (
    // <div className="w-full h-[50vh] bg-bg-dark flex items-center justify-center flex-col  sm:h-full">
    <div className="w-full h-[50vh]  bg-bg-dark flex items-center justify-center flex-col  sm:h-full" >
      <div className=" context">
        <div className="top flex w-full justify-between items-start sm:flex-col sm:gap-5">
          <div className="logo flex flex-col gap-[1rem] py-4">
            <div className="flex">
              <img src={logo} alt="logo img" className="w-[3rem] pl-[-1]" />
              <span className="text-white font-Roboto capitalize font-b">
                Gilbertvincent Shop
              </span>
            </div>
            <p className="text-white font-Roboto pl-2 text-[18px]">
            Bei Expert Repair ist es unsere Leidenschaft,<br/>erstklassige Reparaturdienste für Mobiltelefone,<br/> Laptops und Computer anzubieten.
           </p>

            <div className="flex gap-3 text-[18px] sm:text-[25px] text-white mt-2">
              <FaFacebookF />
              <FaTwitter />
              <FaInstagram />
              <FaLinkedin />
            </div>
          </div>
          <div className="text-white flex flex-col gap-3">
            <span className="text-white font-Lato text-[24px]">Unternehmen</span>
            <ul className="list-custom">
              <li>
                <Link to="/" className="link">
                  Heim
                </Link>
              </li>
              <li>
                <Link to="/aboutus" className="link">
                  Über uns
                </Link>
              </li>
              <li>
                <Link to="/contactus" className="link">
                  Kontaktiere
                </Link>
              </li>
              <li>
                <Link to="/TermsAndConditions" className="link">
                  Terms 
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="link">
                Privatsphäre
                </Link>
              </li>
            </ul>
          </div>

          <div className="text-white flex flex-col gap-3">
            <span className="text-white font-Lato text-[24px]">Services</span>
            <ul className="list-custom">
              <li>
                <Link to="/mobile" className="link">
                  Mobile 
                </Link>
              </li>

              <li>
                <Link to="/tablet" className="link">
                  Tablet
                </Link>
              </li>

              <li>
                <Link to="/ipad" className="link">
                 iPad 
                </Link>
              </li>

              <li>
                <Link to="/laptop" className="link">
                 Laptop
                </Link>
              </li>

              <li>
                <Link to="/computer" className="link">
                  Computer
                </Link>
              </li>
            </ul>
          </div>
          <div className="subcribe sm:w-full text-white flex flex-col gap-[1rem]">
            <h3 className=" capitalize font-Lato text-[24px]">
              Newsletter
            </h3>
            <div className="relative">
      <input
        type="email"
        placeholder="Email address"
        className="w-full rounded-full px-2 py-3 placeholder:capitalize outline-none text-black"
      />
       <button className="btn absolute right-0 top-1 sm:top-0 sm:bottom-0  cursor-pointer text-[20px] sm:text-[25px]">
        {/* You can put the envelope icon here */}
        <FaEnvelope/>
      </button> 
    </div>


          </div>
        </div>
      </div>

      <div className="w-full h-[3rem] relative sm:h-min bg-slight-black pt-4">
        {/* <div className="w-full h-[2px] bg-gray absolute z-[100] left-0 right-0 top-0"></div> */}
 
        <div className=" w-full h-full copyright ">
          <div className=" mb-2 flex items-center justify-center sm:pl-12  copyright  sm:flex-col">
            <div className="text-center">
              <span className="text-white text-center  font-Lato">© 2023 gilbertvincent.ch  All rights reserved.</span>

              <span className=" font-Roboto text-center hover:mxma text-white" >
                <a target="_blank" rel="noreferrer" href="https://matrixmantra.lk/" > | Web Design & Development by Matrix Mantra (Pvt) Ltd </a>
              </span>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;