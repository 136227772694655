import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardModel from "../common/CardModel";

const Model = () => {
  const { id, brandType } = useParams();
  const [models, setModels] = useState([]);

  useEffect(() => {

      // Scroll to the top of the page when the component mounts or brandType changes
      window.scrollTo(0, 0);

    axios
      .get(`${process.env.REACT_APP_API_URL}/model/getmodelbybrandid/${id}`)
      .then((response) => {
        setModels(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <div className="section2 flex-wrap gap-5 items-center ">
      <div className="context">
        <div className="flex flex-col gap-[2rem]">
          <h1 className="h1">
            Choose <span>{brandType} </span>
            model
          </h1>

          <div>
            <div className="flex min-h-[50vh] flex-wrap items-center justify-center gap-5">
              {models.map((model) => (
                <CardModel model={model} key={model._id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Model;
