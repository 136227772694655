import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CardCheck from "../common/CardCeckBox";
import axios from "axios";
import DescriptionAlerts from "../common/Alert";
import "../common/Loding.css";
import Loading from "../common/Loading";
// import theme from "../common/Theme";
// import model_img from "../Img/model.png";
import Popup from "./Popup";

const Parts = () => {
  const { id } = useParams();
  const [parts, setParts] = useState([]);
  const [model, setModel] = useState([]);
  const [imgg, setImgg] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {

      // Scroll to the top of the page when the component mounts or brandType changes
      window.scrollTo(0, 0);

    axios
      .get(`${process.env.REACT_APP_API_URL}/model/${id}`)
      .then((response) => {
        setModel(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [id]);

  
  useEffect(() => {
    if (model && model.parts) {
      setParts(model.parts);
    }
  }, [model]);

  useEffect(() => {
    if (model && model.image) {
      setImgg(model.image);
    }
  }, [model]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCustomerName = (event) => {
    setCustomerName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleCheckboxChange = (id, name, price) => {
    if (selectedCheckboxes.some((checkbox) => checkbox._id === id)) {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((checkbox) => checkbox._id !== id)
      );
    } else {
      setSelectedCheckboxes((prevSelected) => [
        ...prevSelected,
        { _id: id, name: name, price: price },
      ]);
    }
  };

  const calcPrice = () => {
    const totalPrice = selectedCheckboxes.reduce(
      (accumulator, part) => accumulator + part.price,
      0
    );
    return totalPrice;
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/application/create`, {
        customerName: customerName,
        email: email,
        phoneNumber: parseInt(phoneNumber, 10),
        brand: model.brand._id,
        brandName: model.brand.name,
        model: model._id,
        modelName: model.name,
        partsDetails: selectedCheckboxes,
      })
      .then((response) => {
        handleClose();
        setCustomerName("");
        setPhoneNumber("");
        setEmail("");
        setSelectedCheckboxes([]);
        setSeverity("Success");
        setAlertMessage(
          "Thank You! your application has been added sucessfully"
        );
        setOpenSnackbar(true);
        console.log(response.data);
      })
      .catch((err) => {
        setSeverity("Warning");
        setAlertMessage(err.response.data.error);
        setOpenSnackbar(true);
        console.log(err);
      });
  };

  if (isLoading) {
    // Show loading UI
    return <Loading />;
  }

  if (isFirstVisit) {
    window.scrollTo(0, 0);
    setIsFirstVisit(false);
  }

  return (
    <div className="section2 items-center justify-center flex">
      {openSnackbar && (
        <DescriptionAlerts
          handleCloseSnackbar={handleCloseSnackbar}
          severity={severity}
          alertMessage={alertMessage}
        />
      )}
      <div className="context flex flex-col">
        <div className="text-center">
          <h1 className="h1 capitalize">
            Select
            <span className=" bg-bg-dark text-white p-2 m-2 rounded">
              {model.name}
            </span>{" "}
            parts that you needed
          </h1>
        </div>

        <div className="w-full h-[70vh] min-h-[87vh] sm:contents mt-6 flex items-center justify-center">
          <div className="w-1/2 sm:w-full h-full">
            {/* If you have a database to get IMAGE FOR THIS BRAND AND SELECT ITEM */}
            <img
              src={imgg}
              alt="img model"
              className="w-full h-full object-contain object-center repair-parts-img"
            />
          </div>
          <div className="w-1/2 sm:w-full h-full flex-col  flex item-center p-3 justify-center gap-5">
            <div className="w-full overflow-auto  flex flex-col gap-3">
              {parts.map((part) => (
                <CardCheck
                  handleCheckboxChange={handleCheckboxChange}
                  selectedCheckboxes={selectedCheckboxes}
                  part={part}
                  key={part._id}
                />
              ))}
            </div>

            <div className="h3">Total Price: {calcPrice()}CHF</div>
            <div className="flex flex-col">
              Your selected parts:
              {selectedCheckboxes.map((part) => part.name).join(", ")}
            </div>
            <button
              onClick={handleClickOpen}
              className=" bg-bg-dark p-3 text-white rounded-xl text-[1.2rem]"
            >
              Apply to repair
            </button>
          </div>

          {open && (
            <Popup
              handleClose={handleClose}
              brand={model.brand.name}
              selectedCheckboxes={selectedCheckboxes}
              calcPrice={calcPrice()}
              model={model.name}
              handleCustomerName={handleCustomerName}
              handleEmail={handleEmail}
              handlePhoneNumberChange={handlePhoneNumberChange}
              handleSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Parts;
