import React from "react";
import hero from "../../Img/hero-img-1.webp";
import hero_2 from "../../Img/hero-img.webp";
import hero_3 from "../../Img/hero-img-2.webp";
import { Link } from "react-router-dom";
function HeroSection() {
  return (
    <div className="section font-Lato  bg-bg-dark text-white ">
      <section className="context gap-[2rem] flex items-center justify-center sm:flex-col sm:gap-5">
        <div className="left flex w-3/5 flex-col gap-[1rem]   h-full justify-center sm:w-full sm:h-auto">
          <h3 className="flex items-center gap-2 font-mt">
            <div className="w-[0.3rem] h-[1rem] bg-white font-md"></div>
            Schnelle Reparaturen, damit Sie wieder einsatzbereit sind 
          </h3>
          <h1 className="h1 font-Roboto font-md ">
            Wenn es um schnelle und zuverlässige Reparaturen Ihrer Geräte geht, sind Sie bei uns genau richtig
          </h1>
          <p className="text-contactus">
            Haben Sie Probleme mit Ihrem Mobiltelefon, Laptop oder Computer? Zögern Sie nicht, mit uns Kontakt aufzunehmen. Unser Team ist bereit, Sie zu unterstützen und eine Lösung zu finden. Machen Sie den ersten Schritt zur Reparatur Ihrer Technik und kontaktieren Sie uns noch heute!
          </p>
          
          {<Link to="contactus" className="btn">kontaktiere uns</Link>}
        
        </div>

        <div className="right w-2/5 h-[80%] overflow-hidden rounded flex relative sm:w-full sm:h-1/2">
          <img
            src={hero}
            alt="hero img"
            className="w-1/3 h-full object-cover absolute top-0 bottom-0 left-0 z-[15] hover:w-full transition-all cursor-pointer border-spacing-6 border-[3px] rounded-md ease-out duration-300"
          />

          <img
            src={hero_2}
            alt="hero img"
            className="w-1/3 h-full object-cover absolute top-0 bottom-0 left-1/3 z-[10] 
hover:w-full transition-all cursor-pointer border-spacing-6 border-[3px] rounded-md hover:z-[20] hover:left-0 ease-out duration-300"
          />

          <img
            src={hero_3}
            alt="hero img"
            className="w-1/3 h-full object-cover absolute top-0 bottom-0 right-0 z-[5] 
hover:w-full transition-all cursor-pointer border-spacing-6 border-[3px] rounded-md hover:z-[25] ease-out duration-300"
          />
        </div>
      </section>
    </div>
  );
}

export default HeroSection;

// import React, { useState, useEffect } from "react";
// import hero from "../../Img/hero-img.jpeg";
// import hero_2 from "../../Img/hero-img-1.jpeg";
// import hero_3 from "../../Img/hero-img-2.jpeg";
// import { Link } from "react-router-dom";

// function HeroSection() {
//   const images = [hero, hero_2, hero_3];
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   // Function to change the image index
//   const changeImage = () => {
//     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//   };

//   // Use useEffect to update the image on a timer (e.g., every 5 seconds)
//   useEffect(() => {
//     const interval = setInterval(changeImage, 5000); // Change image every 5 seconds

//     // Clean up the interval when the component is unmounted
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="section font-Lato bg-bg-dark text-white">
//       <section className="context gap-[2rem] flex items-center justify-center sm:flex-col sm:gap-5" id="hero-context">
//       <div className="left flex w-3/5 flex-col gap-[1rem]   h-full justify-center sm:w-full">
//            <h3 className="flex items-center gap-2 font-mt">
//              <div className="w-[0.3rem] h-[1rem] bg-orange font-md"></div>
//              Schnelle Reparaturen, damit Sie wieder einsatzbereit sind 
//            </h3>
//            <h1 className="h1 font-Roboto font-md ">
//              Wenn es um schnelle und zuverlässige Reparaturen Ihrer Geräte geht, sind Sie bei uns genau richtig
//            </h1>
//            <p className="text-contactus">
//              Haben Sie Probleme mit Ihrem Mobiltelefon, Laptop oder Computer? Zögern Sie nicht, mit uns Kontakt aufzunehmen. Unser Team ist bereit, Sie zu unterstützen und eine Lösung zu finden. Machen Sie den ersten Schritt zur Reparatur Ihrer Technik und kontaktieren Sie uns noch heute!
//            </p>
          
//            {<Link to="/contactus" className="btn">kontaktiere uns</Link>}
        
//         </div>
//         <div className="right w-2/5 h-[100%] rounded flex relative sm:w-full" id="hero-image">
//           {images.map((src, index) => (
//             <img
//               key={index}
//               src={src}
//               alt={`hero img ${index}`}
//               className={`w-full h-full sm:h-[150%] object-cover absolute top-0 bottom-0 left-${
//                 index / 3
//               } z-[${15 - 5 * index}] hover:w-full transition-all cursor-pointer border-spacing-6 border-[3px] rounded-md ease-out duration-300 ${
//                 index === currentImageIndex ? "" : "opacity-0 pointer-events-none"
//               }`}
//             />
//           ))}
//         </div>
//       </section>
//     </div>
//   );
// }

// export default HeroSection;

