import * as React from "react";
import { useNavigate } from "react-router-dom";

const CartViewBrand = ({ brand }) => {
  const navigate = useNavigate();

  const handleClick = (id, brandType) => {
    navigate(`/model/${id}/${brandType}`);
  };

  return (
    <div
      onClick={() => handleClick(brand._id, brand.brandType)}
      className="w-[10rem] h-[10rem]  drop-shadow bg-white cursor-pointer rounded-xl overflow-hidden "
    >
      <img
        src={brand.image}
        alt={brand.name}
        className="w-full h-4/5 object-cover"
      />
      <div className="flex items-center justify-center w-full h-1/5">
        {brand.name}
      </div>
    </div>
  );
};

export default CartViewBrand;
