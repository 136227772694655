import React from "react";
import { Link } from "react-router-dom";
import logo from "../Img/logo.png";
import { LuMenu } from "react-icons/lu";
import { RiCloseFill } from "react-icons/ri";
import { useState } from "react";

function ResponsiveAppBar() {
  const [menu, setMenu] = useState(null);

  const handleMenu = () => {
    setMenu((menu) => !menu);
  };

  const handleLinkClick = () => {
    setMenu(false); // Close the mobile menu when a link is clicked
    window.scrollTo(0, 0); // Scroll to the top of the page before navigating
  };

  return (
    <div className="">
      <nav className="w-full h-[4rem]  bg-bg-dark flex  justify-between items-center fixed drop-shadow-md top-0 left-0 right-0 z-[1000] text-white  font-Roboto">
        <div className="w-full">
          <Link
            to="/"
            className="flex  items-center capitalize font-Montserrat font-eb text-m sm:text-sm"
          >
            <img src={logo} alt="logo" className="w-[4rem] sm:w-[3rem] h-full" />
            <span className="font-Montserrat uppercase text-lg sm:text-base"><span className="text-2xl sm:text-2xl">G</span>ilbert<span className="text-2xl sm:text-2xl">V</span>incent <span className="text-lg sm:text-base">SHOP</span></span>
          </Link>
        </div>
        <ul className="w-1/2 h-full flex  items-center sm:hidden">
          <li>
            <Link to="/" label="Home" className="link px-4" onClick={handleLinkClick}>
             Home
            </Link>
          </li>

          <li>
            <Link to="/aboutus" className="link px-4" onClick={handleLinkClick}>
              Über uns
            </Link>
          </li>

          <li>
            <Link to="/mobile" label="Mobile" onClick={handleLinkClick}/>
            <Link to="/tablet" label="Tablet" onClick={handleLinkClick}/>
            <Link to="/ipad" label="I Pad" onClick={handleLinkClick}/>
            <Link to="/laptop" label="Labtop" onClick={handleLinkClick}/>
            <Link to="/computer" label="Computer" onClick={handleLinkClick}/>
            <span className="group relative">
              <span className="link">
                <span className="link px-4">Service</span>
              </span>
              <span className=" submenu rounded-md invisible absolute left-0 top-6 flex-col  bg-gray shadow-md w-max  h-max group-hover:visible flex">
                <li className="submenulisthover hover:submenulist">
                  <Link to="/mobile" className="link listtem" onClick={handleLinkClick}>
                    Mobile Service
                  </Link>
                </li>
                <li className=" submenulisthover hover:submenulist">
                  <Link to="/tablet" className="link listtem" onClick={handleLinkClick}>
                    Tablet Service
                  </Link>
                </li>
                <li className=" submenulisthover hover:submenulist"onClick={handleLinkClick}>
                  <Link to="/ipad" className="link listtem">
                    iPad Service
                  </Link>
                </li>
                <li className="submenulisthover hover:submenulist" onClick={handleLinkClick}>
                  <Link to="/laptop" className="link listtem">
                    Laptop Service
                  </Link>
                </li>
                <li className="submenulisthover hover:submenulist" onClick={handleLinkClick}>
                  <Link to="/computer" className="link listtem">
                    Computer Service
                  </Link>
                </li>
              </span>
            </span>
          </li>

          <li>
            <Link to="/contactus" className="link px-4" onClick={handleLinkClick}>
              Kontaktiere uns
            </Link>
          </li>

        </ul>
        <div className="hidden sm:flex pr-5">
          <button onClick={handleMenu}>
            <LuMenu className="text-[2rem] cursor-pointer" />
          </button>
        </div>
        {menu && (
          <div className="w-screen h-screen bg-bg-dark text-white flex fixed top-0 left-0 right-0 bottom-0 z-[2000]">
            <button
              className="fixed top-3 right-3 z-[3000]"
              onClick={handleMenu}
            >
              <div className="w-full flex items-start  gap-8">
          <Link
            to="/"
            className="flex  capitalize font-Montserrat font-eb text-m sm:text-sm"
          >
            <img src={logo} alt="logo" className="w-[4rem] sm:w-[3rem] h-full" />
            <span className="font-Montserrat uppercase text-lg sm:text-base"><span className="text-4xl sm:text-2xl">G</span>ilbert<span className="text-4xl sm:text-2xl">V</span>incent <span className="text-2xl sm:text-2xl">SHOP</span></span>
          </Link>
          <RiCloseFill className="text-[1.5rem]" />
        </div>
            
             

            </button>
            <ul className="w-full h-full flex flex-col gap-3 p-5 mt-10  items-start">
              <li>
                <Link to="/" label="Home" className="link" onClick={handleLinkClick}>
                 Home
                </Link>
              </li>
              <li>
                <Link to="/aboutus" className="link" onClick={handleLinkClick}>
                  Über uns
                </Link>
              </li>
              <li>
                <span className=" relative">
                  <span className="link">
                    <span className="link" onClick={handleLinkClick}>Dienstleistungen</span>
                  </span>
                  <span className="flex-col gap-2 p-2 shadow-md w-max  h-max group-hover:visible flex">
                    <li>
                      <Link to="/mobile" className="link" onClick={handleLinkClick}>
                        Mobile Dienste
                      </Link>
                    </li>
                    <li>
                      <Link to="/tablet" className="link" onClick={handleLinkClick}>
                        Tablet Dienste
                      </Link>
                    </li>
                    <li>
                      <Link to="/ipad" className="link" onClick={handleLinkClick}>
                        iPad Dienste
                      </Link>
                    </li>
                    <li>
                      <Link to="/laptop" className="link" onClick={handleLinkClick}>
                        Laptop Dienste
                      </Link>
                    </li>
                    <li>
                      <Link to="/computer" className="link" onClick={handleLinkClick}>
                        Computer Dienste
                      </Link>
                    </li>
                  </span>
                </span>
              </li>

              <li>
                <Link to="/contactus" className="link" onClick={handleLinkClick}>
                  Kontaktiere uns
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
}

export default ResponsiveAppBar;
