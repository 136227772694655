import React from "react";
import {
  FaMobileAlt,
  FaTabletAlt,
  FaTablet,
  FaLaptop,
  FaDesktop,
} from "react-icons/fa";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
function RepairServices() {
  return (
    <div className="w-full h-full flex flex-col items-center py-[2rem] bg-slight-black">
      <section className="context flex items-center flex-col gap-[3rem] text-white ">
        <div className="flex flex-col items-center gap-3 text-center">
          <h1 className="h1">Services</h1>
          <p className="p1">
            Für unsere Kunden bieten wir Dienstleistungen an, die auf ihre Bedürfnisse zugeschnitten sind
          </p>
        </div>

        <div className="w-full flex  gap-10 flex-wrap items-center justify-center">
          {/* 1 */}
          <Link to="mobile">
            <div className="sub flex flex-col  items-start pt-5">
              <div className="flex w-full flex-col h-1/5 justify-center">
                < FaMobileAlt className="icon " />
              </div>

              <div className="h-3/5 flex flex-col gap-2">
                <h3 className="h3">Mobile Service</h3>
                <p className="p2  overflow-hidden">
                  Egal, ob Ihr Mobiltelefon einen gesprungenen Bildschirm, einen defekten Ladeanschluss oder Softwareprobleme hat, unser Team hilft Ihnen gerne weiter.
                </p>
              </div>

              <button className="w-full flex items-end justify-end h-[2rem]">
                <BsArrowRightCircleFill className="icon2" />
              </button>
            </div>
          </Link>
          {/* 2 */}
          <Link to="tablet">
            <div className="sub flex flex-col  items-start pt-5">
              <div className="flex w-full flex-col h-1/5 justify-center">
                <FaTabletAlt className="icon " />
              </div>

              <div className="h-3/5 flex flex-col gap-2">
                <h3 className="h3">Tablet Service</h3>
                <p className="p2  overflow-hiddense">
                  Tablet-Probleme? Unser Expertenteam kümmert sich um verschiedene Probleme für verschiedene Marken und sorgt für eine schnelle, neuwertige Funktionalität
                </p>
              </div>

              <button className="w-full flex items-end justify-end h-[2rem]">
                <BsArrowRightCircleFill className="icon2" />
              </button>
            </div>
          </Link>
          {/* 3 */}
          <Link to="ipad">
            <div className="sub flex flex-col  items-start">
              <div className="flex w-full flex-col h-1/5 justify-center">
                <FaTablet className="icon " />
              </div>

              <div className="h-3/5 flex flex-col gap-2">
                <h3 className="h3"> iPad Service</h3>
                <p className="p2  overflow-hiddense">
                  iPad-Probleme? Unsere erfahrenen Techniker beheben nicht reagierende Touchscreens, schnelle Batterieentladung und Softwarefehler und stellen die Funktionalität wieder her
                </p>
              </div>

              <button className="w-full flex items-end justify-end h-[2rem]">
                <BsArrowRightCircleFill className="icon2" />
              </button>
            </div>
          </Link>
          {/* 4 */}
          <Link to="laptop">
            <div className="sub flex flex-col  items-start">
              <div className="flex w-full flex-col h-1/5 justify-center">
                <FaLaptop className="icon " />
              </div>

              <div className="h-3/5 flex flex-col gap-2">
                <h3 className="h3">Laptop Service</h3>
                <p className="p2  overflow-hiddesen">
                  Umfassende Laptop-Reparaturen: Fehlerbehebung bei Hardware und Software. Experten reparieren defekte Tastaturen, Trackpads und langsame Leistung effizient
                </p>
              </div>

              <button className="w-full flex items-end justify-end h-[2rem]">
                <BsArrowRightCircleFill className="icon2" />
              </button>
            </div>
          </Link>
          {/* 5 */}
          {/* <Link
            to="computer"
            className="w-full flex items-center justify-center"
          >
            <div className="sub flex flex-col  items-center justify-center w-[31vw]">
              <div className="flex w-full flex-col h-1/5 justify-center">
                <FaDesktop className="icon " />
              </div>
se
              <div className="h-3/5 flex flex-col gap-2">
                <h3 className="h3">Computer Services </h3>
                <p className="p2  overflow-hidden">
                  Computer issues? We cover virus removal, crashes, slow performance, hardware upgrades, software installations, and more for optimal performance.
                </p>
              </div>

              <button className="w-full flex items-end justify-end h-[2rem]">
                <BsArrowRightCircleFill className="icon2" />
              </button>
            </div>
          </Link> */}


          <Link to="computer">
            <div className="sub flex flex-col  items-start">
              <div className="flex w-full flex-col h-1/5 justify-center">
                <FaDesktop className="icon " />
              </div>

              <div className="h-3/5 flex flex-col gap-2">
                <h3 className="h3">Computer Service</h3>
                <p className="p2  overflow-hidden">
                  Computerprobleme? Wir decken Virenentfernung, Abstürze, langsame Leistung, Hardware-Upgrades, Softwareinstallationen und mehr an, um eine optimale Leistung zu gewährleisten
                </p>
              </div>

              <button className="w-full flex items-end justify-end h-[2rem]">
                <BsArrowRightCircleFill className="icon2" />
              </button>
            </div>
          </Link>

        </div>
      </section>
    </div>
  );
}

export default RepairServices;
