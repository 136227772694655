import React from "react";
import photo from "../../Img/Nikola Tesla.png";
// import { FaQuoteLeft } from "react-icons/fa";
const QuotePage = () => {
  return (
    <div className="section font-mono pt-[0px] sm:pb-[0px] bg-slight-black">
      <div className="context h-[80vh] sm:flex-col flex-row-reverse  text-white justify-center items-center gap-5 rounded">
        <div className="w-1/2  sm:w-full sm:p-[0px] p-[3rem] border-gray rounded flex">
          <img
            src={photo}
            alt="Nikola Tesla"
            className=" bg-slight-black w-auto object-contain" id="tesla"
          />
        </div>

        <div className="w-1/2 sm:w-full  p-[2rem] rounded relative  sm:text-[12px] sm:text-clip h-full">
          {/* <FaQuoteLeft className=" absolute -top-5 left-5 text-[4rem] font-md text-milk-white" /> */}
          <blockquote className=" ">
          Wenn die drahtlose Kommunikation perfekt eingesetzt wird, verwandelt sich die ganze Erde in ein riesiges Gehirn, was sie tatsächlich auch ist, da alle Dinge Teile eines realen und rhythmischen Ganzen sind. Wir werden unabhängig von der Entfernung sofort miteinander kommunizieren können. Und nicht nur das: Durch Fernsehen und Telefon werden wir einander so perfekt sehen und hören, als wären wir Angesicht zu Angesicht, trotz dazwischenliegender Entfernungen von Tausenden von Kilometern, und die Instrumente, mit denen wir all dies erreichen können, werden dies auch tun passen in unsere Westentaschen
          </blockquote>
          <p className="font-b pt-3  float-right">- Nikola Tesla, 1926</p>
        </div>
      </div>
    </div>
  );
};

export default QuotePage;
