import React from "react";
import {
  FaUserMd,
  FaTools,
  FaStopwatch,
  FaMoneyBillWave,
  FaHandsHelping,
  FaWpbeginner,
} from "react-icons/fa";
function Whycontactus() {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center bg-bg-dark text-white py-[2rem]">
      <section className="context flex items-center flex-col gap-[3rem] bg-milk-white">
        <div className="flex flex-col items-center text-center">
          <h1 className="h1 ">Warum uns wählen?</h1>
        </div>

        <div className="w-full flex flex-wrap gap-10 justify-center">
          {/* 1 */}

          {/* <div className="w-[20rem] h-[18rem] text-white p-3 overflow-hidden rounded-xl flex flex-col gap-5 why-choose"> */}
          <div className="w-[20rem] h-[18rem] text-white p-3 overflow-hidden  flex flex-col gap-5 why-choose">
            <div className="w-[3rem] h-[3rem] p-1 bg-gray rounded-full flex items-center justify-center">
              <FaUserMd className="icon3 text-white" />
            </div>
            <div className="p-0">
              <h3 className="h3">EXPERTENTECHNIKER</h3>
              <p className="p2 text-contactus">
              Erfahrene Techniker, die über Branchentrends auf dem Laufenden sind, bieten präzise Diagnosen und effektive Lösungen für alle Ihre mobilen Reparaturanforderungen.
              </p>
            </div>
          </div>

          {/* 2 */}

          <div className="w-[20rem] h-[18rem] text-white p-3 overflow-hidden  flex flex-col gap-5 why-choose">
            <div className="w-[3rem] h-[3rem] p-1 bg-gray rounded-full flex items-center justify-center">
              <FaTools className="icon3 text-white" />
            </div>
            <div className="p-0">
              <h3 className="h3"> QUALITÄTSREPARATUR</h3>
              <p className="p2 text-contactus">
              Hochwertige Reparaturen für alle Geräte unter Verwendung hochwertiger Ersatzteile für optimale Leistung und Langlebigkeit Ihres Mobilgeräts.
              </p>
            </div>
          </div>

          {/* 3 */}

          <div className="w-[20rem] h-[18rem] text-white p-3 overflow-hidden  flex flex-col gap-5 why-choose">
            <div className="w-[3rem] h-[3rem] p-1 bg-gray rounded-full flex items-center justify-center">
              <FaStopwatch className="icon3 text-white" />
            </div>
            <div className="p-0">
              <h3 className="h3">SCHNELLE BEARBEITUNGSZEIT</h3>
              <p className="p2 text-contactus">
              Schnelle Bearbeitungszeit, Verständnis der Bedeutung Ihres Geräts. Effiziente Reparaturprozesse, engagierte Techniker sind bestrebt, Ihr Gerät schnell zurückzusenden.
              </p>
            </div>
          </div>

          {/* 4 */}

          <div className="w-[20rem] h-[18rem] text-white p-3 overflow-hidden  flex flex-col gap-5 why-choose">
            <div className="w-[3rem] h-[3rem] p-1 bg-gray rounded-full flex items-center justify-center">
              <FaMoneyBillWave className="icon3 text-white" />
            </div>
            <div className="p-0">
              <h3 className="h3">TRANSPARENTE PREISE</h3>
              <p className="p2 text-contactus">
                Transparente Preise, wettbewerbsfähige Tarife, detaillierte Vorabangebote. Keine versteckten Kosten oder Überraschungen, was das Vertrauen in unsere Dienstleistungen gewährleistet.
              </p>
            </div>
          </div>

          {/* 5 */}

          <div className="w-[20rem] h-[18rem]  text-white p-3 overflow-hidden  flex flex-col gap-5 why-choose">
            <div className="w-[3rem] h-[3rem] p-1 bg-gray rounded-full flex items-center justify-center">
              <FaHandsHelping className="icon3 text-white" />
            </div>
            <div className="p-0">
              <h3 className="h3">EXZELLENTER KUNDENSERVICE</h3>
              <p className="p2 text-contactus">
                Kundenzufriedenheit ist unsere Priorität. Freundliches, sachkundiges Kundendienstteam, das Ihnen während des gesamten Reparaturvorgangs zur Seite steht.
              </p>
            </div>
          </div>

          {/* 6 */}

          <div className="w-[20rem] h-[18rem] text-white p-3 overflow-hidden  flex flex-col gap-5 why-choose">
            <div className="w-[3rem] h-[3rem] p-1 bg-gray rounded-full flex items-center justify-center">
              <FaWpbeginner className="icon3 text-white" />
            </div>
            <div className="p-0">
              <h3 className="h3">BREITES LEISTUNGSSPEKTRUM</h3>
              <p className="p2 text-contactus">
                Umfassende mobile Reparaturdienste: Bildschirmaustausch, Batteriereparaturen, Software-Fehlerbehebung, Behebung von Wasserschäden und mehr durch erfahrene Techniker.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Whycontactus;
