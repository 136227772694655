import * as React from "react";
import { GrClose } from "react-icons/gr";
export default function DescriptionAlerts({
  handleCloseSnackbar,
  severity,
  alertMessage,
}) {
  return (
    <div
      onClick={handleCloseSnackbar}
      className="fixed top-[5rem]  z-[6000] drop-shadow-2xl cursor-pointer  border-gray w-screen h-screen backdrop-blur-sm flex  justify-center"
    >
      <div className="bg-white p-5 w-auto h-min border-[2px]">
        <div className="relative">
          <GrClose className=" absolute top-1 right-1 capitalize" />
          <div>{severity}</div>
          {alertMessage}
        </div>
      </div>
    </div>
  );
}
