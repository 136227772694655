import React from 'react'
import { Box, Typography,CircularProgress } from '@mui/material';

const Loading = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress color="primary" size={40} />
        <Typography variant="body1" color="textSecondary" style={{ marginTop: 10 }}>
          Loading...
        </Typography>
      </Box>
  )
}

export default Loading
