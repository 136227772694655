// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import CartViewBrand from "../common/CartViewBrand";
// import { useParams } from "react-router-dom";

// const Brand = () => {
//   const { brandType } = useParams();
//   const [brands, setBrands] = useState([]);

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_API_URL}/brand/${brandType}`)
//       .then((response) => {
//         setBrands(response.data);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, [brandType]);

//   return (
//     <div className="section2">
//       <div className="context flex flex-col gap-[2rem]">
//         <h1 className="h1">Wählen Sie Markentyp</h1>
//         <div className="w-screen h-full p-5 flex items-center justify-evenly flex-wrap gap-5 ">
//           {brands.map((brand) => (
//             <CartViewBrand brand={brand} key={brand._id} />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Brand;

import axios from "axios";
import React, { useEffect, useState } from "react";
import CartViewBrand from "../common/CartViewBrand";
import { useParams } from "react-router-dom";

const Brand = () => {
  const { brandType } = useParams();
  const [brands, setBrands] = useState([]);

  useEffect(() => {

      // Scroll to the top of the page when the component mounts or brandType changes
      window.scrollTo(0, 0);

    axios
      .get(`${process.env.REACT_APP_API_URL}/brand/${brandType}`)
      .then((response) => {
        // Custom sorting function to place "iPhone" first and "Samsung" second
        const sortedBrands = response.data.sort((a, b) => {
          if (a.name === "iPhone") {
            return -1; // "iPhone" comes before any other brand
          } else if (b.name === "iPhone") {
            return 1; // "iPhone" comes after any other brand
          } else if (a.name === "Samsung") {
            return -1; // "Samsung" comes before any other brand except "iPhone"
          } else if (b.name === "Samsung") {
            return 1; // "Samsung" comes after any other brand except "iPhone"
          } else {
            return a.name.localeCompare(b.name); // Sort other brands alphabetically
          }
        });
        setBrands(sortedBrands);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [brandType]);


  return (
    <div className="section2">
      <div className="context flex flex-col gap-[2rem]">
        <h1 className="h1">Wählen Sie Markentyp</h1>
        <div className="w-screen h-full min-h-[50vh] p-5 flex items-center justify-evenly flex-wrap gap-5 ">
          {brands.map((brand) => (
            <CartViewBrand brand={brand} key={brand._id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brand;

