import React from "react";

function TermsAndConditions() {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-semibold text-center mb-6">
        Gilbert Vincent Shop
      </h1>
      <h2 className="text-2xl font-semibold mb-4">Terms & Bedingungen</h2>

      <div className="space-y-8">
        <div className="space-y-2">
          <h3 className="text-xl font-semibold">Nutzungsbedingungen</h3>
          {/* Content for "Terms of Service" */}
        </div>

        <div className="space-y-2">
          <h3 className="text-xl font-semibold">1. Allgemein</h3>
            Die Allgemeinen Geschäftsbedingungen (AGB) sind Bestandteil der Reparaturleistungen von Gilbert Vincent Shop. Als Verkäufer fungiert Gilbert Vincent Shop. Diese Allgemeinen Geschäftsbedingungen gelten für alle von uns durchgeführten Reparaturverträge, Lieferungen und Leistungen. Mit der Bestellung von Reparaturleistungen aus unserem Sortiment erklären Sie sich mit diesen Bedingungen einverstanden. Sollte eine Bestimmung dieser Allgemeinen Geschäftsbedingungen aus irgendeinem Grund unwirksam sein, so bleibt die Wirksamkeit der übrigen Bestimmungen davon unberührt. Alle von Gilbert Vincent Shop gegenüber dem Kunden erbrachten Reparaturleistungen und Produkte erfolgen ausschließlich auf der Grundlage der nachstehenden Allgemeinen Geschäftsbedingungen. Abweichungen von diesen Bedingungen sind nur gültig, wenn sie zwischen Gilbert Vincent Shop und dem Kunden schriftlich vereinbart wurden
        </div>

        <div className="space-y-2">
          <h3 className="text-xl font-semibold">2. Angebot und Preise</h3>
            Das Angebot und die Preise von Gilbert Vincent Shop können ohne vorherige Ankündigung geändert werden. Alle angegebenen Preise verstehen sich einschließlich der geltenden Steuern, sofern nicht anders angegeben. Angebote sind zeitlich begrenzt gültig und unterliegen der Produktverfügbarkeit. Im Falle von Preisfehlern oder Unstimmigkeiten behält sich Gilbert Vincent Shop das Recht vor, den Preis entsprechend zu korrigieren und anzupassen. Der Endpreis für Reparaturdienstleistungen kann je nach spezifischem Problem und benötigten Teilen variieren. Dies wird dem Kunden vor Beginn der Reparatur mitgeteilt. Die Zahlung für Reparaturleistungen ist nach Fertigstellung fällig, eventuelle zusätzliche Kosten oder Gebühren werden vorab mit dem Kunden besprochen. Gilbert Vincent Shop behält sich das Recht vor, Reparaturanfragen abzulehnen und kann bei Bedarf alternative Lösungen anbieten. Alle Garantiedetails und -bedingungen werden dem Kunden nach Abschluss der Reparatur mitgeteilt.
        </div>

        <div className="space-y-2">
          <h3 className="text-xl font-semibold">3. Lieferung</h3>
            Die Lieferung reparierter Geräte erfolgt an die vom Kunden bei der Bestellung angegebene Adresse. Die voraussichtliche Lieferzeit wird dem Kunden bei der Reparaturannahme mitgeteilt. Die Lieferzeiten können jedoch aufgrund von Faktoren, die außerhalb unserer Kontrolle liegen, variieren und wir haften nicht für etwaige Verzögerungen. Der Kunde ist für die Richtigkeit der Lieferadresse und Kontaktinformationen verantwortlich. Etwaige zusätzliche Versandkosten aufgrund falscher oder unvollständiger Angaben gehen zu Lasten des Kunden. Bei der Lieferung muss der Kunde oder sein Bevollmächtigter das Gerät auf etwaige Schäden oder Unstimmigkeiten untersuchen und uns diese innerhalb von 24 Stunden melden. Für den Versand gelten die Allgemeinen Geschäftsbedingungen des mit der Beförderung beauftragten Kurier- oder Versandunternehmens
        </div>

        <div className="space-y-2">
          <h3 className="text-xl font-semibold">4. Teillieferungen</h3>
           Wenn immer möglich, wird Gilbert Vincent Shop den Reparaturservice und die Bestellung als Ganzes durchführen. Der Käufer ist mit der Annahme von Teillieferungen einverstanden. Hier fallen keine zusätzlichen Kosten an.
        </div>

        <div className="space-y-2">
          <h3 className="text-xl font-semibold">
            5. Lieferzeit / Ausführungszeit der Reparatur
          </h3>
            Eine verbindliche Angabe von Lieferzeiten/Ausführungszeiten gibt es nicht. Reparierte Handys und Lagerware werden per A-Post an den Kunden verschickt. Eine verspätete Anreise, gleich aus welcher Ursache, berechtigt in keinem Fall zum Abzug des Rechnungsbetrages und begründet keinen Anspruch auf Schadensersatz. Dies gilt insbesondere auch für den Fall, dass der Kunde aufgrund der Lieferverzögerung ein Ersatzprodukt anmieten muss.
        </div>

        <div className="space-y-2">
          <h3 className="text-xl font-semibold">
            6. Rückgabe und Umtausch, Terminabsage
          </h3>
            Ein Umtausch oder eine Rücknahme vertragsgemäß gelieferter Ware und durchgeführter Reparaturen ist nicht möglich. Wichtig: Gebuchte Express-Reparaturtermine sind verbindlich. Wir reservieren den Termin exklusiv für Sie. Sollten Sie den Termin nicht einhalten und nicht mindestens 6 Stunden vorher per E-Mail absagen, werden Ihnen 50 % des Preises der gebuchten Reparatur in Rechnung gestellt.
        </div>

        <div className="space-y-2">
          <h3 className="text-xl font-semibold">7. Garantieleistungen </h3>
            Gilbert Vincent Shop gewährt 3 Monate Garantie auf Reparaturen und die ausgetauschten Teile. Die Garantiezeit beginnt mit der Rückgabe bzw. Abholung des reparierten Mobiltelefons. Der Käufer ist verpflichtet, etwaige Mängel unverzüglich Gilbert Vincent Shop zu melden und deren Anweisungen für einen Umtausch oder eine Reparatur zu befolgen. Defekte Waren, unabhängig von ihrem Gewicht oder ihrer Größe, müssen vom Käufer an einen von Gilbert Vincent Shop mitgeteilten Rückgabeort gesendet oder geliefert werden. Kosten und Gefahr dieses Transportes trägt der Käufer.
        </div>

        {/* Add more sections for each point in the Terms & Conditions */}
      </div>

      <div className="my-6">
        <p className="font-semibold">Gilbert Vincent Geschäft</p>
        <p className="mb-2">Dorf 25,</p>
        <p className="mb-2">6162 Entlebuch</p>
        <p className="mb-2">Schweiz</p>
      </div>

      <p className="text-sm text-gray-600">
        Gültig ab November 2023<br />
        Letzte Aktualisierung: 05.08.2023
      </p>
    </div>
  );
}

export default TermsAndConditions;
