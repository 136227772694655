// Redesign
import HeroSection from "./Sections/HeroSection";
import NicolusTesla from "./Sections/QuotePage";
import RepairServices from "./Sections/RepairServices";
import Whycontactus from "./Sections/Whycontactus";

const Home = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center overflow-hidden">
      <HeroSection />
      <RepairServices />
      <Whycontactus />
      <NicolusTesla />
    </div>
  );
};

export default Home;
