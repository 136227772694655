import React from "react";
import { GrClose } from "react-icons/gr";
function Popup({
  handleClose,
  model,
  brand,
  handleCustomerName,
  handlePhoneNumberChange,
  handleEmail,
  handleSubmit,
  selectedCheckboxes,
  calcPrice,
}) {
  return (
    <div className="w-screen h-screen sm:h-full z-[2000] fixed   top-0 bottom-0 left-0 right-0 backdrop-blur-md flex items-center justify-center font-Roboto">
      <div className="w-1/2 h-auto sm:w-full bg-white rounded-xl drop-shadow p-5 relative">
        <GrClose
          className=" cursor-pointer absolute top-5 right-5"
          onClick={handleClose}
        />
        <div className="w-full text-center p-3 flex flex-col gap-5 sm:gap-1">
          <h1 className="h1 text-center">Apply for repair</h1>
          <p>
            To appy to repair your device, please enter your information here.
            We will contact you within 10 min.
          </p>
        </div>

        <div className="w-full  flex flex-col p-3">
          <div>
            <div className="w-full p-5 flex flex-col gap-2 bg-light-gray rounded font-Lato">
              <div className="flex items-center gap-5">
                Your mobile brand: <span>{brand}</span>
              </div>
              <div className="flex items-center gap-5">
                Your mobile model: <span>{model}</span>{" "}
              </div>
              <div className="flex items-center gap-5">
                Total price: <span>{calcPrice} $</span>
              </div>
              <div className="flex items-center gap-5">
                Your selected parts:
                {selectedCheckboxes.map((part) => part.name).join(", ")}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col m-2 gap-3">
          <div className="w-full">
            <h1 className="h3 text-center">Your Details</h1>
          </div>
          <div className="flex p-3 flex-col gap-2">
            <input
              type="text"
              name="name"
              placeholder="name"
              className="p-3 text-bg-dark placeholder:text-gray border-[2px] border-milk-white rounded outline-none capitalize w-full"
              onChange={handleCustomerName}
            />

            <input
              type="text"
              name="email"
              placeholder="Email"
              className="p-3 text-bg-dark placeholder:text-gray border-[2px] border-milk-white rounded outline-none  w-full"
              onChange={handleEmail}
            />

            <input
              type="text"
              name="phone"
              placeholder="mobile number"
              className="p-3 text-bg-dark placeholder:text-gray border-[2px] border-milk-white rounded outline-none capitalize w-full"
              onChange={handlePhoneNumberChange}
            />
          </div>
        </div>
        <div className="w-full flex items-center justify-center gap-10">
          <button onClick={handleClose} className="btn bg-bg-dark text-white">
            Cancel
          </button>
          <button onClick={handleSubmit} className="btn drop-shadow-md">
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

export default Popup;
