import React from "react";
import { Checkbox } from "@mui/material";

const CardCeckBox = ({ handleCheckboxChange, selectedCheckboxes, part }) => {
  const { _id, name, price } = part;

  const handleCardClick = () => {
    handleCheckboxChange(_id, name, price);
  };

  return (
    <div className="w-full rounded-xl border-[2px] p-3 flex items-center justify-center drop-shadow cursor-pointer">
      <div onClick={handleCardClick} className="w-full">
        <div className="flex items-center gap-5">
          <Checkbox
            checked={selectedCheckboxes.some(
              (checkbox) => checkbox._id === _id
            )}
            onChange={() => handleCheckboxChange(_id, name, price)}
          />
          <div className="flex flex-col capitalize">
            <div>{name}</div>

            <div className="flex items-center h3">Price: {price} CHF</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCeckBox;
